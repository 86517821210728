// *************************************
//
// Example Component
//
// *************************************

.c-product-hover {
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(/assets/images/Henley_category2.jpg);
  // background-size: cover;
  height: 190px;
  font-family: "garamond-premier-pro";
  font-size: 14px;
  margin-bottom: 1em;
  margin-top: 1em;
  p {
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
    margin-bottom: 15px;
    font-style: italic;
  }
  span {
    font-family: "acumin-pro-wide";
    font-size: 20px;
    text-transform: uppercase;
    font-style: normal;
  }
}

.c-product-hover:hover {
  color: #000;
  background-color: #E8E8E8 !important;
  background-image: none !important;
  -webkit-filter: none;
  filter: none;
  span {
    color: $maroon;
  }
}
