// *************************************
//
// Accordion Component
//
// *************************************

.o-accordion {
  background-color: #E8E8E8;
  .accordion-item {
    border: 1px solid $maroon;
  .accordion-title {
    font-size: 15px;
    color: $maroon;
    border: 0;
  }
  .accordion-title:hover {
    background-color: $maroon;
    color: #fff;
  }
}
.is-active{
  background-color: $maroon;
  .accordion-content {
    border: 0;
    padding:0;
  }
  a {
    color: #fff !important;
  }
    a {
      display: block;
      padding: 1.1rem .9rem;
      background-color: #69231B;
      border: 2px solid #712F27;
    }
}
}

.accordion-title::before {
  background-color: $maroon;
  color: #fff;
  padding: .5em;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  line-height: 6px;
  text-align: center;
  font-size: 0.75rem;
}

.accordion-title:hover::before {
  background-color: #B4918D;
  color: $maroon;
}

.is-active > .accordion-title::before {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  line-height: 6px;
  text-align: center;
  background-color: #B4918D;
  color: $maroon;
}
