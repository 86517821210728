// *************************************
//
// Maps Styles
//
// *************************************

.c-contact-map-container {
  position: relative;
  // padding-bottom: 56.25%;
  // height: 0;
  overflow: hidden;
  max-width: 100%;
  pointer-events: none;

  min-height: 340px;
  max-height: 340px;

  @include breakpoint (medium up) {
    min-height: 265px;
    max-height: 265px;
  }

  @include breakpoint (large up) {
    min-height: 335px;
    max-height: 335px;
  }

  // embed,
  // iframe,
  // object {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  // }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    width: 100%;
    height: 100%;
  }
}
