// *************************************
//
// Example Component
//
// *************************************

.c-product-simple {
  color: $navy;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  background-color: #e8e8e8;
  margin-top: 1.5em;
  .c-product-heading {
    padding-top: .7em;
    padding-bottom: .7em;
    font-size: 20px;
    margin-bottom: 0;
  }
}

.c-product-simple__white {
  background-color: #fff;
}
