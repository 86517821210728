// *************************************
//
// Footer Nav Component
//
// *************************************

.o-list-inline__footer {
  list-style-type: none;
  a {
    color: #fff;
  }
  @include breakpoint(medium down) {
    font-family: "garamond-premier-pro";
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

.o-list-inline__footer > li:first-child {
  @include breakpoint(large up) {
    font-family: "garamond-premier-pro";
    font-size: 26px;
    font-style: italic;
  }
}

.o-list-inline__footer > li {
  font-size: 15px;
  line-height: 37px;
  @include breakpoint(medium down) {
    font-family: "garamond-premier-pro";
    font-size: 26px;
    font-style: italic;
  }
}
