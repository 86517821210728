// *************************************
//
// Site Header Styles
//
// *************************************

// Top Navigation
.top-bar {
  @include breakpoint(medium down) {
    background-color: #fff;
    padding-right: 0;
    padding-left: 0;
  }
  padding-right: 47px;
  padding-left: 47px;
  background-color: $maroon;
  color: #fff;

  .top-bar-title {
    font-size: 20px;
    font-weight: lighter;
    @include breakpoint(medium down) {
      font-size: 20px;
      font-weight: lighter;
      width: 100%;
    }
    span {
      @include breakpoint(medium down) {
        float: right;
        background-color: #69231b;
        padding-bottom: .2em;
        padding-left: .2em;
        padding-right: .2em;
        padding-top: .1em;
        margin-top: 1em;
      }
    }
    img {
      float: left;
      padding-left: .5em;
      @include breakpoint(large up) {
        display: none;
      }
    }
    .o-title--phone {
      @include breakpoint(medium down) {
        display: none;
      }
      @include breakpoint(large up) {
        float: left;
      }
    }
  }

}
.top-bar .menu{
  background-color: $maroon;
  float: right;

  li a{
    color: #fff;
    padding: 0.7rem .6rem;
    &:hover {
      color: #0E9C8B;
    }
  }

  li.social a{
    padding: 3px;
    font-size: 23px;
    &:hover {
      color: #0E9C8B;
    }
  }

}

.menu input[type=search] {
  border-radius: 50px;
  margin-left: 1em;
  background-image: url("/assets/images/mag-glass.png");
  background-repeat: no-repeat;
  background-position-x: 93%;
  background-position-y: 46%;
  background-size: 25px 25px;
  width: 11em;
}

.mobile-icons {
  @include breakpoint(large up) {
    display: none;
  }
  display: inline;
  float: right;
  margin-top: 1em;
  i {
    color: #69231b;
    font-size: 32px;
    padding-right: .5em;
  }
}
.o-menu-desktop {
  @include breakpoint(medium down) {
    display: none;
  }
}

.o-menu-mobile {
  width: 100% !important;
  @include breakpoint(large up) {
    display: none;
  }
}

.is-drilldown {
  max-width: 100% !important;
  min-height: 0 !important;
  @include breakpoint(large up) {
    display: none;
  }
}

.top-bar-right {
      width: 100% !important;
      @include breakpoint(large up) {
        width: auto !important;
      }
}

.top-bar-left {
  @include breakpoint(medium down) {
    width: 100% !important;
  }
}
#responsive-menu {
  width: 100% !important;
}

.is-drilldown-submenu-parent > a::after {
    border-color: transparent transparent transparent #ffffff;
}

.js-drilldown-back > a::before {
    border-color: transparent #ffffff transparent transparent;
}

// Main menu
.o-menu-lower {
  padding-top: 15px;
  padding-bottom: 15px;
  @include breakpoint(large down) {
    padding-left: 44px;
    padding-right: 59px;
  }

  .o-list-inline {
    margin: 0;
    padding: 0;
    list-style: none;
    float: right;
    @include breakpoint(large down) {
          margin-top: 1.5em;
    }

    .o-list-inline:first-child {
      float:left;
    }

    .o-list-inline__item {
      display: inline-block;
      margin-right: 0.5em;

      a {
        color: #000;
        &:hover {
          color: #0E9C8B;
        }
      }
    }
    .main-nav-logos {
      @include breakpoint(large down) {
        display: none;
      }
    }
  }
}

// drop down styles

.menu > li > a {
    padding: 0;
    &:hover {
      color: #0E9C8B;
    }
}

.is-dropdown-submenu {
   margin-top: 2.5em;
}

.is-submenu-item, .is-dropdown-submenu-item a {
   padding: 0.7rem 1rem;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
    border-color: #000000 transparent transparent;
}

/* Search Style */
#search {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(42,39,39,0.9);
    -webkit-transition: all 0.5s ease-in-out;
       -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
	       -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
    -webkit-transform: translate(0px, -100%) scale(0, 0);
	     -moz-transform: translate(0px, -100%) scale(0, 0);
	      -ms-transform: translate(0px, -100%) scale(0, 0);
         -o-transform: translate(0px, -100%) scale(0, 0);
	          transform: translate(0px, -100%) scale(0, 0);
    opacity: 0;
    display: none;
}

#search.open {
    -webkit-transform: translate(0px, 0px) scale(1, 1);
       -moz-transform: translate(0px, 0px) scale(1, 1);
    	  -ms-transform: translate(0px, 0px) scale(1, 1);
	       -o-transform: translate(0px, 0px) scale(1, 1);
	           transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
    z-index: 106;
    display: block;
}

#search input[type="search"] {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -51px;
    width: 60%;
    margin-left: 20%;
    color: rgb(255, 255, 255);
  	background: transparent;
  	border-top: 1px solid rgba(255, 255, 255, .9);
  	border-bottom: 2px solid rgba(255, 255, 255, .5);
  	border-left: 0px solid transparent !important;
  	border-right: 0px solid transparent !important;
    border-radius: 0;
    font-size: 40px;
    font-weight: 300;
    text-align: center;
    outline: none;
    padding-top: 30px;
    padding-bottom: 30px;
    @include breakpoint(small down) {
    font-size: 30px;
    }
}


#search input[type="search"]:focus {
  box-shadow: none;
}

#search .close {
    position: fixed;
    top: 15px;
    right: 15px;
	  opacity: 1;
	  font-size: 27px;
  	color: #fff;
}

#search .close:hover{
  color: #FC2121;
  cursor: pointer;
}
