// *************************************
//
//  Mixins
//
// *************************************

// -------------------------------------
//  Nth-child mixins - by @paulund
//  https://paulund.co.uk/nth-child-sass-mixins
// ------------------------------------

// First child mixin
// Select the first child

@mixin first-child() {
  &:first-of-type {
    @content;
  }
}

// Last child mixin
// Select the last child

@mixin last-child() {
  &:last-of-type {
    @content;
  }
}

// Even mixin
// Select even children

@mixin even() {
  &:nth-child(even) {
    @content;
  }
}

// Odd mixin
// Select odd children

@mixin odd() {
  &:nth-child(odd) {
    @content;
  }
}

// First mixin
// Select first n children

@mixin first($num) {
  @if $num == 1 {
    &:first-child {
      @content;
    }
  } @else {
    &:nth-child(-n + #{$num}) {
      @content;
    }
  }
}

// Last mixin
// Select last n children

@mixin last($num) {
  &:nth-last-child(-n + #{$num}) {
    @content;
  }
}

// After mixin
// Select children after n

@mixin after($num) {
  &:nth-child(n+#{$num + 1}) {
    @content;
  }
}

// From end mixin
// Select n children from the end

@mixin from-end($num) {
  &:nth-last-child(#{$num}) {
    @content;
  }
}

// Between mixin
// Select children between two numbers

@mixin between($first, $last) {
  &:nth-child(n+#{$first}):nth-child(-n+#{$last}) {
    @content;
  }
}

// All but mixin
// Select all but the nth child

@mixin all-but($num) {
  &:not(:nth-child(#{$num})) {
    @content;
  }
}

// Each mixin
// Select each nth child

@mixin each($num) {
  &:nth-child(#{$num}n) {
    @content;
  }
}

// Font mapping mixin
// Pull font sizes from foundation type maps to use elsewhere.
// Simply include with heading type: @include header-size(h2);

@mixin header-size($type){
  font-size: rem-calc(map-get(map-get(map-get($header-styles, "small"), $type), "font-size") * 1px);
  @include breakpoint(medium){
    font-size: rem-calc(map-get(map-get(map-get($header-styles, "medium"), $type), "font-size") * 1px);
  }
}

// Simple Transition Mixin
// ChangeMe Mixin

@mixin changeMe() {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all	0.3s ease;
}
