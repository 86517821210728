// *************************************
//
//Search Card Component
//
// *************************************

.o-search-results {
  border: 1px solid #69231B;
  padding: 2em;
  margin-bottom: 2em;
  h2 {
    color: #69231B !important;
  }
  p {
    color: #000 !important;
  }
}
