// *************************************
//
//  Images
//
// *************************************

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}
