// *************************************
//
// Text Block Component
//
// *************************************

.o-text-block {
  text-align: center;
  padding-top: 4em;
    padding-bottom: 4em;
  h2 {
    font-family: "garamond-premier-pro";
    font-size: 25px;
  }
}

.o-text-block__maroon {
  background-color: $maroon;
  color: #fff;
}

.o-text-block--border {
  @include breakpoint(medium down) {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: .5em;
  }
}
