// *************************************
//
// Mobile Expander Component
//
// *************************************

.content-class {
  position: relative;
  margin: 0 auto;
  background: $maroon;
  color: #fff;
  padding: 20px;
}

.content-class__light {
  position: relative;
  margin: 0 auto;
  background: #fff;
  color: #000;
  padding: 20px;
}

.js-show-content {
  display: none;
}

@media (max-width: 767px) {
.is-expanded {
  .content-inner {
    max-height: initial;
    @include changeMe;

    &::after {
      display: none;
    }
  }

  .js-show-content {
    display: none;
  }
}
  .content-inner {
    position: relative;
    max-height: 150px;
    overflow: hidden;
    text-align: center;
  }

  .js-show-content {
    display: block;
    color: #fff;
    font-family: "acumin-pro-wide";
    margin: 0 auto;
    margin-top: 1em;
  }

  .js-show-content__button {
    font-size: 15px;
    color: #fff;
    padding: 12px 31px 12px 31px;
    border: 1px solid #fff;
    border-radius: 50px;
    margin-top: 21px;
    text-transform: uppercase;
    margin-top: 2em;
    display: inline-block;
  }

  .js-show-content__button-light {
    display: block;
    color: $maroon;
    border: 1px solid #ccc;
    font-family: "acumin-pro-wide";
    margin: 0 auto;
    margin-top: 1em;
    font-size: 15px;
    padding: 12px 31px 12px 31px;
    border-radius: 50px;
    text-transform: uppercase;
    margin-top: 2em;
  }

  .content-inner::after {
    content: '';
    width: 100%;
    height: 70px;
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom, transparent, $maroon);
  }
  .content-inner--light::after {
    content: '';
    width: 100%;
    height: 70px;
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom, transparent, #fff);
  }
}
