// *************************************
//
// Example Component
//
// *************************************

.c-card {
  background: $maroon;
  color: #fff;
  padding: 100px 38px 60px 38px;
  text-align: center;
  height: 100%;
  @include breakpoint(medium down) {
    padding-top: 50px;
  }

  .c-card__title {
    font-size: 33px;
    text-transform: uppercase;
  }

  .c-card__subtitle {
    font-size: 24px;
    font-family: "garamond-premier-pro";
  }

  p {
    font-size: 15px;
  }
}

.c-card--light {
  background: #fff;
  color: $navy;
}

.c-card--dark {
  background-color: #333;
}

.c-card__text-left {
  text-align: left;
}

.c-card__subtitle--bordered {
  @include breakpoint(medium down) {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding-top: .5em;
    padding-bottom: .5em;
  }
}

.c-card__subtitle--bordered__white {
  @include breakpoint(small down) {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding-top: .5em;
    padding-bottom: .5em;
  }
}


.c-card__title-maroon {
  color: $maroon;
}

.c-card__subtitle-maroon {
  color: $maroon;
}

.c-card__subtitle-black {
  color: #000;
}
