@if (config(healthcheck) == true) {
/*------------------------------------*\
  #HEALTHCHECK
\*------------------------------------*/

  /**
   * Highlight types of class to visually see the makeup of our UIs.
   */
  [class^="o-"],
  [class*=" o-"] {
    outline: 10px solid orange;
  }

  [class^="c-"],
  [class*=" c-"] {
    outline: 10px solid cyan;
  }

  [class^="u-"],
  [class*=" u-"] {
    outline: 10px solid violet;
  }

  [class^="_"],
  [class*=" _"] {
    outline: 10px solid red;
  }

} // endif
