// *************************************
//
// Call To Action Component
//
// *************************************

.c-card-cta{
  background-image: url("/assets/images/visit-waterford-cta.jpg");
  background-size: cover;
  padding: 3em 2em 3em 2em;
  background-position: right;
  background-repeat: no-repeat;
  @include breakpoint(medium down) {
    background-image: url("/assets/images/card-long-tablet.jpg");
  }
  @include breakpoint(small down) {
    background-image: url("/assets/images/card-bg-mobile.jpg");
    padding-bottom: 9em;
  }

  .o-card-long__heading {
    line-height: 34px;
    @include breakpoint(small down) {
      font-size: 29px;
      line-height: 35px;
    }
    color: $maroon;
    font-size: 25px;
  }

  .o-card-long__subheading {
    font-family: "garamond-premier-pro";
    font-size: 25px;
    color: $maroon;
    margin-bottom: 8px;
  }

  .o-card-long__text {
    font-size: 15px;
    margin-bottom: 0;
  }
  @include breakpoint(small down) {
    text-align: center;
  }
}

.card--showroom {
  background-image: url("/assets/images/cta.jpg");
  padding-top: 5em;
  padding-bottom: 5em;
  @include breakpoint(medium down) {
    background-image: url("/assets/images/showroom-bg-tablet.jpg");
  }
  @include breakpoint(small down) {
    background-image: url("/assets/images/showroom-mobile-bg.png");
    padding-bottom: 22em;
  }
  .o-card-long__heading {
    color: #69231B;
    font-size: 30px;
  }
}
