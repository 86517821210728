// *************************************
//
//  Buttons
//
// *************************************

// Base button styles
.c-button {
  @include changeMe;
  font-size: 15px;
  color: #fff;
  padding: 12px 31px 12px 31px;
  border: 1px solid #fff;
  border-radius: 50px;
  margin-top: 21px;
  text-transform: uppercase;
  margin-top: 2em;
  display: inline-block;

  &:hover {
    background: #4FC1B3;
    color: $white;
  }

  &.active,
  &:active,
  &:focus,
  &:visited {
    color: $white;
    border: 1px solid $white;
  }

  &:visited {
    &:hover {
      background: #4FC1B3;
      color: $white;
      border: 1px solid #4FC1B3;
    }
  }
}

// Themed buttons
.c-button {

  // Light button
  &__light {
    color: $maroon;
    border: 1px solid #ccc !important;

    &:hover {
      background: $maroon;
      color: $white;
    }

    &.active,
    &:active,
    &:focus,
    &:visited {
      color: $maroon;
      border: 1px solid #ccc;
    }

    &:visited {
      &:hover {
        background: $maroon;
        color: $white;
      }
    }
  }

  // Maroon Button
  &__maroon {
    color: $maroon;
    border: 1px solid #fff;

    &:hover {

    }

    &.active,
    &:active,
    &:focus,
    &:visited {
      color: $maroon;
      border: 1px solid #fff;
    }
  }
}
