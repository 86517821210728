// *************************************
//
// Products Component
//
// *************************************

.c-home-products {
  background-color: #69231B;
    padding-top: 2em;
    padding-bottom: 2em;
}
