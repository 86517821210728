// *************************************
//
// Slider / Carousel Components
//
// *************************************
// General Slider styles
.slick-slide {
  &:focus {
    outline: 0 none;
  }
}
// Brand / Product Slider
.product-slider {
  background-color: #541312;
  @include breakpoint(large-up) {
    padding-bottom: 4em;
  }

  h2 {
    text-align: center;
    color: #fff;
    margin-top: 1.4em;
    margin-bottom: .9em;
  }
}

.product-slider {
  .slick-next:before,
  .slick-prev:before {
    color: #fff;
  }
}
// Blog Post Slider
.c-posts {
  margin-bottom: 4em;

  h2 {
    color: $maroon;
    text-align: center;
    margin-bottom: 1.2em;
  }
}

.chevron-white {
      color: #fff;
}
