/* ==========================================================================
   #MEDIA
   ========================================================================== */

/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
$o-media__spacing: 10px;
$o-media__spacing-tiny: 5px;
$o-media__spacing-small: 10px;
$o-media__spacing-large: 20px;
$o-media__spacing-huge: 30px;

.o-media {
  @include clearfix();
  display: block;
}

.o-media__img {
  float: left;
  margin-right: $o-media__spacing;

  > img {
    display: block;
  }
}

.o-media__body {
  overflow: hidden;
  display: block;

  &,
  > :last-child {
    margin-bottom: 0;
  }
}

/* Size variants
   ========================================================================== */

/**
 * Modify the amount of space between our image and our text. We also have
 * reversible options for all available sizes.
 */

.o-media--tiny {
  > .o-media__img {
    margin-right: $o-media__spacing-tiny;
  }

  &.o-media--reverse {
    > .o-media__img {
      margin-right: 0;
      margin-left: $o-media__spacing-tiny;
    }
  }
}

.o-media--small {
  > .o-media__img {
    margin-right: $o-media__spacing-small;
  }

  &.o-media--reverse {
    > .o-media__img {
      margin-right: 0;
      margin-left: $o-media__spacing-small;
    }
  }
}

.o-media--large {
  > .o-media__img {
    margin-right: $o-media__spacing-large;
  }

  &.o-media--reverse {
    > .o-media__img {
      margin-right: 0;
      margin-left: $o-media__spacing-large;
    }
  }
}

.o-media--huge {
  > .o-media__img {
    margin-right: $o-media__spacing-huge;
  }

  &.o-media--reverse {
    > .o-media__img {
      margin-right: 0;
      margin-left: $o-media__spacing-huge;
    }
  }
}

/* Reversed media objects
   ========================================================================== */

.o-media--reverse {
  > .o-media__img {
    float: right;
    margin-right: 0;
    margin-left: $o-media__spacing;
  }
}

/* Gutterless media objects
   ========================================================================== */

.o-media--flush {
  > .o-media__img {
    margin-right: 0;
    margin-left: 0;
  }
}
