// *************************************
//
// Bare list
//
// *************************************

.o-list-bare {
  padding: 0;
  margin: 0;
  list-style: none;
}
