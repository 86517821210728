// *************************************
//
//  Headings
//
// *************************************

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.5rem;
  font-weight: inherit;
  line-height: 1.2;
}
