// *************************************
//
//  Search
//
// *************************************

input[type=search] {
  border-radius: 50px;
  margin-left: 1em;
}

.transparent {
  margin-left: 1em;
  background-color: transparent;
  color: #fff;
  margin: 0;
}
