// *************************************
//
// Blog Nav Component
//
// *************************************

.c-blog-filter {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #9D6F6A;
  display: inline-block;
  border-radius: 50px;

  @include breakpoint(medium down) {
    width: 100%;
  }

  li {
    display: inline-block;
    display: inline-block;
    text-transform: uppercase;
    margin: 1em;

    .c-blog-filter__btn {
      @include changeMe;
      color: #9D6F6A;

      &:hover {
        color: $white;
      }

      &.active {
        color: $white;
      }

      &:focus {
        outline: 0 none;
      }
    }

    a {
      @include changeMe;
      color: #9D6F6A;

      &:hover {
        color: $white;
      }
    }
  }
}

.c-blog-filter__container {
  padding-left: 45px;
  padding-right: 45px;

  margin-left: auto;
  margin-right: auto;

  .is-accordion-submenu-parent {
    margin: 0;
  }

  .c-blog-filter__initial-link {
    padding-top: 1rem;
    padding-bottom: 1rem;

    &:after {
      border-color: transparent transparent transparent;
    }
  }
}
