// *************************************
//
// Brand block with 4 brand cards + text
//
// *************************************

.c-quad-brands {
  display: flex;
  flex-direction: column;
  @include breakpoint(large up) {
    margin-top: 1em;
    margin-bottom: 2em;
  }
  @include breakpoint(medium down) {
    flex-direction: initial;
  }

  &.flipped {
    @include breakpoint(large up) {
      flex-direction: column-reverse;
    }
  }

  @include breakpoint (large up) {
    flex-direction: row;

    &.flipped {
      flex-direction: row-reverse;
    }
  }
  .brands-padding {
    padding-left: .4em;
  }
  .brands-padding-right {
    padding-right: .4em;
  }
}
