// *************************************
//
// Text Block With Images Component
//
// *************************************

.o-text-block__images {
  text-align: left;
  h3 {
    font-size: 32px;
    color: #69231B;
    text-transform: uppercase;
  }
}
