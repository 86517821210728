/*------------------------------------*\
  #MAIN
\*------------------------------------*/

/**
 * CONTENTS
 *
 * SETTINGS
 * Custom...............Custom-specific theming and config.
 * All..................Because our Settings layer doesn’t actually produce any
 *                      CSS, we can safely glob all of the files into one import
 *                      without risk of bloating our compiled stylesheet. This
 *                      also allows us to easily recycle all of our
 *                      project-level settings into other Sass file/projects.
 *                      Please see `settings/_all.scss` for a full table of
 *                      contents.
 *
 * TOOLS
 * All..................Because our Tools layer doesn’t actually produce any
 *                      CSS, we can safely glob all of the files into one import
 *                      without risk of bloating our compiled stylesheet. This
 *                      also allows us to easily recycle all of our
 *                      project-level tooling into other Sass file/projects.
 *                      Please see `tools/_all.scss` for a full table of
 *                      contents.
 *
 * GENERIC
 * Box-sizing...........Better default `box-sizing`.
 * Normalize.css........A level playing field.
 * Reset................A pared back reset to remove margins.
 * Shared...............Sensibly and tersely share global commonalities.
 *
 * ELEMENTS
 * Page.................Page-level styles (HTML element).
 * Headings.............Heading styles.
 * Links................Hyperlink styles.
 * Lists................Default list styles.
 * Images...............Base image styles.
 * Quotes...............Styling for blockquotes, etc.
 *
 * OBJECTS
 * Wrappers.............Wrappers and page constraints.
 * Layout...............Generic layout module.
 * Headline.............Simple object for structuring heading pairs.
 * Media................The media object.
 * List-bare............Lists with no bullets or indents.
 * List-inline..........Simple abstraction for setting lists out in a line.
 * Crop.................A container for cropping image and media content.
 *
 * COMPONENTS
 * Logo.................Make our logo a reusable component.
 * Page.................Full page wrapper.
 * Page-head............Page header styles.
 * Page-foot............Page footer styles.
 * Nav primary..........The site’s main nav.
 * Nav secondary........Secondary nav styles.
 * Nav tertiary.........Horizontally scrolling nav.
 * Masthead.............Site’s main masthead.
 * Sub-content..........Secondary/supporting content.
 * Panel................Simple panelled boxout.
 * Score................Score lozenge for place ratings.
 * Buttons..............Button styles.
 * Avatar...............General avatar styles.
 * Testimonial..........Quote styles.
 * Calendar.............Simple static calendar component.
 * Headline.............Basic heading style for generic headlines.
 * Promo................Promotional box styling.
 * Pagination...........Responsive pagination component.
 *
 * SCOPES
 * Prose................Set up a new styling context for long-format text.
 *
 * THEMES
 * Red..................Simple red theme for the site.
 * Custom...............Custom-specific overrides.
 *
 * UTILITIES
 * Headings.............Reassigning our heading styles to helper classes.
 * Widths...............Simple width helper classes.
 * Healthcheck..........Visual health-check tool.
 * Debug................Highlight potentially troublesome code.
 * Shame.css............Short-term hacks and quick-fixes.
 */

@import "imports/imports";
@import "settings/settings.global";
@import "settings/settings.foundation";


@import "tools/tools.mixins";
@import "tools/tools.typography";
@import "tools/tools.clearfix";
@import "tools/tools.foundation";

@import "generic/generic.box-sizing";
@import "generic/generic.normalize";
@import "generic/generic.reset";

@import "../components/01-elements/buttons/buttons.scss";
@import "../components/01-elements/headings/headings.scss";
@import "../components/01-elements/image/image.scss";
@import "../components/01-elements/input/input.scss";
@import "../components/01-elements/link/link.scss";
@import "../components/01-elements/paragraph/paragraph.scss";
@import "../components/01-elements/search/search.scss";

@import "../components/02-objects/lists/list--bare.scss";
@import "../components/02-objects/lists/list--inline.scss";
@import "../components/02-objects/media/media.scss";
@import "../components/02-objects/pack/pack.scss";

@import "../components/03-ui/accordion/accordion.scss";
@import "../components/03-ui/blocks/blocks.scss";
@import "../components/03-ui/blog-card/blog-card.scss";
@import "../components/03-ui/blog-filter/blog-filter.scss";
@import "../components/03-ui/brands/brands.scss";
@import "../components/03-ui/call-to-action/call-to-action.scss";
@import "../components/03-ui/card/card.scss";
@import "../components/03-ui/footer-nav/footer-nav.scss";
@import "../components/03-ui/form/form.scss";
@import "../components/03-ui/hero/hero.scss";
@import "../components/03-ui/maps/maps.scss";
@import "../components/03-ui/mobile-expander/mobile-expander.scss";
@import "../components/03-ui/product-hover/product-hover.scss";
@import "../components/03-ui/product-simple/product-simple.scss";
@import "../components/03-ui/products/products.scss";
@import "../components/03-ui/search-card/search-card.scss";
@import "../components/03-ui/site-footer/site-footer.scss";
@import "../components/03-ui/site-header/site-header.scss";
@import "../components/03-ui/slider/slider.scss";
@import "../components/03-ui/table/table.scss";
@import "../components/03-ui/text-block-with-images/text-block-with-images.scss";
@import "../components/03-ui/text-block/text-block.scss";

@import "utilities/utilities.headings";
@import "utilities/utilities.widths";
@import "utilities/utilities.healthcheck";
@import "utilities/utilities.debug";
@import "utilities/utilities.shame";
