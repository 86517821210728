// *************************************
//
// Table Component
//
// *************************************

.c-table {
  width:100%;
  padding-top: 3em;
  padding-bottom: 3em;
  thead{
    background-color: #333;
    color: #fff;
    text-align: left;
  }
  tbody{
    border:0;
    td{
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 18px;
      font-weight: 300;
      border-bottom: 1px solid #ccc;
    }
    tr:nth-child(even) {
    background-color: #fff;
}
  }
  th{
    padding: 18px;
    font-weight: 400;
  }
}

.table-hide {
  @include breakpoint(medium down) {
    display:none;
  }
}

.table-show {
  @include breakpoint(large up) {
    display:none;
  }
}

.c-table--mobile {
  h2 {
    color: $maroon;
    text-align: center;
  }
  img {
    object-fit: cover;
    width: 100%;
  }
}
