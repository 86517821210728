// *************************************
//
// Inline list
//
// *************************************

.o-list-inline {
  margin: 0;
  padding: 0;
  list-style: none;
}

.o-list-inline__item {
  display: inline-block;
}
