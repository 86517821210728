// *************************************
//
// Hero Styles
//
// *************************************

$hero-heading: $header-font-family;
$hero-heading-font-size: 41px;
$hero-subheading: $body-font-family;
$hero-subheading-font-size: 29px;

// Base plain hero
.c-hero {
  background-color: #000;

  padding-top: 30px;
  padding-bottom: 30px;

  @include breakpoint (medium up){
    padding-top: 30px;
    padding-bottom: 35px;
  }

  @include breakpoint (large up){
    padding-top: 60px;
    padding-bottom: 60px;
  }

  // Hero Text
  .c-hero__heading {
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    font-size: $hero-heading-font-size;
    line-height: 1;
    color: #fff;
  }
}

// Hero with Image
.c-hero {
  &--image {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;

    // Hero with image image
    .c-hero-image-container {
      @include breakpoint(large up) {
        height: 600px;
      }
      .c-hero__image {
        @include breakpoint(large up) {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .c-hero__image {
        @include breakpoint(small only) {
          display: none;
        }
      }
    }

    // Hero with image text
    .c-hero__text {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.75);
      color: #fff;
      text-align: center;

      padding-top: 30px;
      padding-bottom: 30px;

      @include breakpoint (medium up){
        padding-top: 15px;
        padding-bottom: 15px;
      }

      @include breakpoint (large up){
        padding-top: 35px;
        padding-bottom: 45px;
      }

      @include breakpoint(small only) {
        position: relative;
        background-color: #000;
      }

      .c-hero__heading {
        text-transform: uppercase;
        font-size: $hero-heading-font-size;
      }
      .c-hero__subheading {
        font-family: "garamond-premier-pro";
        // font-family: $body-font-family;
        font-size: $hero-subheading-font-size;
        // font-size: 28px;
      }
    }
  }
}

// Hero Carousel
.c-hero {
  &--carousel {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;

    // Carousel Slide
    .c-carousel__slide {
      position: relative;
    }

    // Hero Carousel image
    .c-hero__image {
      @include breakpoint(small only) {
        display: none;
      }
    }

    // Hero Carousel Caption
    .c-carousel__caption-container {
      position: relative;

      @include breakpoint (medium up) {
        z-index: 10;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }

    .c-carousel__caption {
      position: relative;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.75);
      color: #fff;
      text-align: center;
      padding-top: 2em;
      padding-bottom: 3em;

      @include breakpoint(small only) {
        position: relative;
        background-color: #000;
      }

      .c-hero__heading {
        text-transform: uppercase;
        font-size: $hero-heading-font-size;
        // font-size: 40px;
        // line-height: 21px;
        padding-top: 0;
        padding-bottom: 0;
      }
      .c-hero__subheading {
        font-family: "garamond-premier-pro";
        // font-family: $body-font-family;
        font-size: $hero-subheading-font-size;
        line-height: 1;
      }
    }

    // Carousel Arrows
    .slick-next {
      @include changeMe;
      width: 30px;
      height: 45px;
      position: absolute;
      bottom: 2rem;
      right: 2rem;
      top: initial;
      transform: perspective(1px) translateY(-50%);
      z-index: 500;
      color: $white;

      &:before {
        content: '';
      }

      span {
        display: inline-block;
        font-size: 45px;
      }
    }
    .slick-prev {
      @include changeMe;
      width: 30px;
      height: 45px;
      position: absolute;
      bottom: 2rem;
      left: 2rem;
      top: initial;
      transform: perspective(1px) translateY(-50%);
      z-index: 500;
      color: $white;

      &:before {
        content: '';
      }

      span {
        display: inline-block;
        font-size: 45px;
      }
    }
  }
}

.c-carousel__image {
      height: 600px;
      width: 100%;
      object-fit: cover;
}

// .slick-track {
//   height:600px !important;
//   img {
//     height: 100%;
//     width: 100%;
//     object-fit: cover;
//   }
// }
