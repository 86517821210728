// *************************************
//
//  Paragraphs
//
// *************************************

p {
  font-size: 14px;
  line-height: 26px;
  font-weight: 300;
  margin-bottom: 15px;
}
