///*----------------------------------*\
//  #GLOBAL
//\*----------------------------------*/
// Project-level settings and setup.


// div, section {
//   border: 1px solid red;
// }

body {
  font-size: 15px;
  line-height: 20px !important;
  font-family: "acumin-pro-wide" !important;
}

a {
  text-decoration: none;
}

.c-text__maroon {
  background-color: #69231B;
}

.c-blog-details {
  background-color: #69231B;
  padding-top: 3em;
  padding-bottom: 3em;
}

.c-cover-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.c-posts {
  padding-top: 4.5em;
}

.o-blog-content {
  background-color: #69231B;
  padding-bottom: 3em;

  .o-blog-navigation {
    text-align: center;
    margin-top: 3em;
    margin-bottom: 3em;
  }
}

.c-about-text {
  margin-top: 4.5em;
  margin-bottom: 6em;
}

.o-news-container__content {
  margin: 1em;
}
