// *************************************
//
// Block Styles
//
// *************************************

$maroon: #69231B;
$sienna-light-gray: #e8e8e8;
$brick-red: #b8202c;
$mustard-yellow: #cc9c3c;
$sienna-blue: #006b8c;
$olive-green: #969069;

.c-block {
  padding-top: 40px;
  padding-bottom: 40px;

  @include breakpoint (large up) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  // C block themes

  // White
  &--white {
    background: $white;
  }

  // Light Grey
  &--light-grey {
    background: $sienna-light-gray;
  }
  &--light-gray {
    background: $sienna-light-gray;
  }

  // Maroon
  &--maroon {
    background: $maroon;
  }

  // Brick red
  &--brick-red {
    background: $brick-red;
  }

  &--blog-detail {
    background: $maroon;
    padding: 42px 32px 50px;

    @include breakpoint (medium up) {
      padding: 30px;
    }

    @include breakpoint (large up) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  &--brands {
    background: $maroon;
    padding: 35px 35px 42px;

    @include breakpoint (medium up) {
      padding: 25px 50px 35px;
    }

    @include breakpoint (large up) {
      padding-top: 40px 36px 45px;
    }
  }

  &--product-video {
    padding-top: 45px;
    padding-bottom: 0;

    @include breakpoint (medium up) {
      padding-top: 40px;
    }

    @include breakpoint (large up) {
      padding-top: 45px;
      padding-bottom: 45px;
    }
  }
}

// Text Plus Image Block
.c-block {
  &--txt-plus-img {
    display: flex;
    flex-direction: column;

    @include breakpoint (medium up) {
      flex-direction: row;
    }

    &.flipped {
      flex-direction: column;

      @include breakpoint (medium up) {
        flex-direction: row-reverse;
      }

      .c-block-content-container {
        @include breakpoint (large up) {
          align-items: flex-end;
        }
      }
    }

    &.dark {
      .c-block-content-container {
        background-color: #333;
        color: $white;
      }

    }

    &.light {

      .c-block-content-container {
        background: $white;
        color: $navy;
      }

      .c-block__title {
        color: $maroon;
      }

      .c-block__subtitle {
        @include breakpoint (small only) {
          border-top: 1px solid #ccc !important;
          border-bottom: 1px solid #ccc !important;

          p {
            border-top: 1px solid #ccc !important;
            border-bottom: 1px solid #ccc !important;
          }
        }
      }

      .c-button {
        color: $maroon;
        border: 1px solid #ccc;

        &:hover {
          background: $maroon;
          color: $white;
        }
      }
    }

    .c-block-image-container {
      display: inline-flex;
      flex-direction: column;
      flex-basis: 50%;

      .c-block__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include breakpoint (small only) {
        .c-block__image {
          min-height: 340px;
          max-height: 340px;
        }
      }

      @include breakpoint (medium up) {
        .c-block__image {
          min-height: 300px;
          max-height: none;
        }
      }

      @include breakpoint (large up) {
        .c-block__image {
          min-height: 465px;
        }
      }
    }

    .c-block-content-container {
      // display: inline-flex;
      // flex-direction: column;
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      background: $maroon;
      color: $white;

      padding-top: 45px;
      padding-bottom: 50px;

      .c-block-content-container__inner {
        text-align: center;

        padding-left: 40px;
        padding-right: 40px;

        @include breakpoint (medium up) {
          padding-left: 16px;
          padding-right: 16px;
        }

        @include breakpoint (large up) {
          max-width: 600px;
          padding-left: 45px;
          padding-right: 45px;
          margin: 0 auto;
        }
      }

      .c-block__title {
        @include header-size(h2);
        line-height: 1;
        text-transform: uppercase;
        margin-bottom: .5em;
      }

      .c-block__subtitle {
        font-size: 24px;
        line-height: 1;
        font-family: "garamond-premier-pro";

        margin-bottom: .5em;

        p {
          font-size: 24px;
          font-family: "garamond-premier-pro";
        }

        @include breakpoint (small only) {
          padding-top: .5em;
          padding-bottom: .5em;

          border-top: 1px solid #fff;
          border-bottom: 1px solid #fff;

          p {
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
          }
        }
      }

      .c-block__body {
        font-size: 15px;

        p {
          font-size: 15px;
          line-height: 1;
        }
      }
    }
  }
}

// Text plus Video Block
.c-block--video {
  .c-block-image-container {
    position: relative;
  }

  .magnific {
    height: 100%;

    &:focus {
      outline: 0 none;
    }
  }

  .c-video__thumbnail-play-btn {
    @include changeMe;
    position: absolute;
    top: 50%;
    left: 50%;

    width: 90px;
    height: 90px;

    margin-top: -45px;
    margin-left: -45px;

    background: rgba($maroon, 0.8);
    color: $white;

    text-align: center;
    border-radius: 50%;

    &:before {
      font-size: 3.8rem;
      margin-top: 0.9rem;
      margin-left: 0.4rem;
    }

    &:hover {
      background: rgba($maroon, 1);
    }
  }
}

// Image plus Image Block

@include breakpoint (small only) {
  .margin-bottom {
    margin-bottom: 1em;
  }
}

// Text plus Video Block About Us Page Only

.c-block--video__about {
  background-color: #69231B;
  padding-top: 2em;
}
