// *************************************
//
// Blog Card
//
// *************************************

// Blog Card Container
.c-blog-card-container {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
}

// Base Blog Card
.c-card-blog {
  background-color: #fff;
  color: #000;
  text-align: center;
  margin-bottom:1.5em;

  &--dark {
    background-color: $maroon;
    color: #fff;

    .c-card-title {
      color: $white !important;
    }
  }

  .c-image-container {
    position: relative;

    &.noimage {
      .c-card-category {
        background-color: $black;
        position: relative;
      }
    }
  }

  // Category black transparent area
  .c-card-category {
    @include header-size(h3);
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.68);
    margin-bottom: 0;
    padding: .2em;
    font-family: "garamond-premier-pro";
    font-style: italic;
    color: #fff;
  }

  // Main card text
  .c-card-body {
    padding-left: 3em;
    padding-right: 3em;
    padding-bottom: 2em;

    p {
      padding-left: 3em;
      padding-right: 3em;
      padding-bottom: 2em;
      padding:0;
    }
  }

  // Card title, h2 made to look like h4 with mixin
  .c-card-title {
    @include header-size(h4);
    font-size: 28px;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    color: $maroon;
    text-transform: uppercase;
  }
}

// Blog Detail Card
.c-blogcard__full {
  background-color: #fff;
  color: #000;

  @include breakpoint (large up) {
    max-width: 682px;
    margin-left: auto;
    margin-right: auto;
  }
}

.c-blogcard__full__container {
  position: relative;
}

.c-blogcard__full__content {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  padding-left: 3em;
  padding-right: 3em;
  @include breakpoint(small down) {
    text-align: center;
  }
}

.c-blogcard__full__title {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.68);
    margin-bottom: 0;
    padding: .3em;
    font-size: 23px;
    font-style: italic;
    font-family: "garamond-premier-pro";
    color: #fff;
    text-align: center;
}

.c-blogcard__full h4{
  color: $maroon;
  font-size: 33px;
  font-weight: 400;
  text-align: center;
  width: 100%;
}

.c-blogcard__full p{
  font-size: 17px;
}

.c-blogcard__full .shareicons{
  font-size: 17px;
  margin-top: .7em;
  i {
    font-size: 22px;
    color: #69231B;
    margin-right: .3em;
    margin-left: .3em;
  }
  @include breakpoint(small down) {
    text-align: center;
    display: inline-block;
  }
}

.c-blogcard__full .blognav{
  text-align: left;
  a {
   margin-right: .5em;
  }
  @include breakpoint(small only) {
    .small-centered {
      justify-content: center;
    }
  }
}


// Not sure what this is for ?? (Sam..)
.c-home-touchpoint__about_us {
  @include breakpoint(medium down) {
    background-color: #69231B;
    padding-top: 2em;
    padding-bottom: 2em;
  }
}
