// *************************************
//
//  Inputs
//
// *************************************

input {
  border-radius: 25px 25px 25px 25px;
  padding: 1rem;
  margin-bottom: 1rem;
}
