// *************************************
//
// Form Component
//
// *************************************

.c-form {
  background-color: #fff;
  color: #000;
  text-align: center;
  @include breakpoint(small down) {
    margin-top: 3em;
    margin-bottom: 2em;
  }
  input[type="text"], textarea {

  background-color: #EAEAEA;

}
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #8E8E8E;
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #8E8E8E;
    opacity: 1;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #8E8E8E;
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #8E8E8E;
  }
}

.c-form input,
textarea {
  border: 0;
  margin: 0;
}

.c-form__container {
  position: relative;
}

.c-form__content {
  padding: 1.5em 3em 2em;
  @include breakpoint(small down) {
    background-color: #69231B;
    color: #fff;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  form {
    @include breakpoint(small down) {
      padding: .5em;
    }
  }
  a.c-button__light {
    @include breakpoint(small down) {
      color: #fff;
    }
  }
}

.c-form__title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.68);
  margin-bottom: 0;
  padding: 0.3em;
  font-size: 23px !important;
  font-style: italic;
  font-family: "garamond-premier-pro";
  color: #fff;
}

.c-form h4 {
  color: $maroon;
  font-size: 37px;
  @include breakpoint(small down) {
    color: #fff;
  }
}

.c-form .has-padding {
  padding: 0.3em;
}

.c-form p {
  text-align: center;
  font-size: 17px;
  color: #000;
  @include breakpoint(small down) {
    color: #fff;
  }
  a {
    color: #000;
    @include breakpoint(small down) {
      color: #fff;
    }
  }
}

.opening-hours {
  padding-left: 8em;
  @include breakpoint(medium down) {
    text-align: center;
    padding-top: 2em;
    padding-left: 0;
  }
}

.background-mobile {
  @include breakpoint(small down) {
    background-color: #69231B;
  }
}

.background-mobile-dark {
  @include breakpoint(medium down) {
    background-color: #541212;
  }
}

.c-contact-main {
  background-color: #69231B;
  padding-top: 3em;
  padding-bottom: 3em;
  color: #fff;
  @include breakpoint(medium down) {
    background-color: #541212;
  }
  @include breakpoint(small down) {
    background-color: #69231B;
    padding-bottom: 0;
  }

  h3 {
    font-family: "garamond-premier-pro";
    font-style: italic;
    font-size: 38px;
  }

  p{
        margin-bottom: 2.5em;
  }

  a.address-link {
    color: #fff;
  }
}

.form_submit {
  background-color: transparent;
  margin-top: 1em;
  @include breakpoint(small down) {
    background-color: transparent;
    color: #fff;
  }
}
