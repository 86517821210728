// *************************************
//
// Site Footer
//
// *************************************
// Base Footer Styles

.c-home-footer-main {
  background-color: #69231B;

  .footer-desktop {
    width: 100%;
    padding-top: 3em;
  }

  .footer-mobile {
    @include breakpoint(medium down) {
      text-align: center;
    }

    .o-list-inline__item {
      @include breakpoint(small down) {
        padding: 0.5em;
      }
    }
  }
}
// Footer plain text

.footer-text {
  h4 {
    font-family: "garamond-premier-pro";
    font-size: 26px;
    font-style: italic;
    color: #fff;
  }

  p {
    font-size: 14px;
    line-height: 19px;
    font-weight: 300;
    margin-bottom: 0;
    color: #fff;
  }

  a {
    color: #fff;

    &:hover {
      color:#0E9C8B;
    }
  }
}
// Footer Menu

.o-list-inline__footer {
  list-style-type: none;
li {
  color: #fff;
}
  a {
    color: #fff;
    &:hover {
      color:#0E9C8B;
    }
  }
  @include breakpoint(medium down) {
    font-family: "garamond-premier-pro";
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

.o-list-inline__footer > li:first-child {
  @include breakpoint(large up) {
    font-family: "garamond-premier-pro";
    font-size: 26px;
    font-style: italic;
  }
}

.o-list-inline__footer > li {
  font-size: 15px;
  line-height: 37px;
  @include breakpoint(medium down) {
    font-family: "garamond-premier-pro";
    font-size: 26px;
    font-style: italic;
  }
}
// Footer Map

.o-footer-map {
  border: 7px solid #89312B;
  margin-bottom: 1em;
  iframe {
    pointer-events: none;
  }
}
// Footer Logos

.o-menu-footer-logos {
  padding-top: 2em;
  padding-bottom: 3em;

  .o-list-inline__footer-logos {
    @include breakpoint(small down) {
      text-align: center;
    }
    img {
      @include breakpoint(medium up) {
        margin-right: .7em;
      }
    }
  }
}
// Footer Search and Social bar

.o-footer-top {
  background-color: #541312;
  padding: 1.5em;

  .o-footer-search {
    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #fff;
    }

    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
      opacity: 1;
    }

    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
      opacity: 1;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #fff;
    }
    .transparent {
      background-image: url("/assets/images/mag-glass-white.png");
      background-repeat: no-repeat;
      background-position-x: 93%;
      background-position-y: 46%;
      background-size: 17px 17px;
      margin-left: 0;
      color:#000;
      font-size: 14px;
      padding-left: 1em;
      height: 47px;
      width: 250px;
      @include breakpoint(medium down) {
        display: none;
      }
    }
  }

  .footer-phone {
    color: #fff;
    margin-bottom: 0;
    font-size: 22px;
    @include breakpoint(large up) {
      display: none;
    }
    @include breakpoint(small up) {
      font-size: 17px;
    }
  }

  .o-list-inline {
    float: right;
    padding: 0.5em;

    li, a {
      color: #fff;
      padding: 0.7rem 0.6rem;
      &:hover {
        color:#0E9C8B;
      }

      i {
        font-size: 20px;
      }
    }
  }

  .social {
    li {
      padding: 0;
    }
    a {
      padding: 0;
      padding-left: .6em;
    }
  }
}

.extra-padding {
  margin-top: 2em;
}
// Footer Copyright Area

.o-footer-bottom {
  background-color: #541312;
  color: #fff;
  padding: 2em;
  @include breakpoint(small down) {
    text-align: center;
  }

  p {
    font-size: 15px;
    margin: 0;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .logo {
    text-align: right;
    @include breakpoint(small down) {
      text-align: center;
      margin-top:2em;
    }
  }
}
